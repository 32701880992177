import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Blog1 from "../../images/blog/blog1.jpg";
import Blog2 from "../../images/blog/blog2.jpg";
import Blog3 from "../../images/blog/blog3.jpg";
import Blog4 from "../../images/blog/blog4.jpg";

const {useRef, useState, useEffect} = React;


const blogAPI = [
    {
        src: Blog1,
        alt: "Blog1",
        title: "How to Hire the Right Node.js Developer?",
        text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
        date: 'November 24, 2021',
    },
    {
        src: Blog2,
        alt: "Blog2",
        title: "How to Hire the Right Node.js Developer?",
        text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
        date: 'November 24, 2021',
    },
    {
        src: Blog3,
        alt: "Blog3",
        title: "How to Hire the Right Node.js Developer?",
        text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
        date: 'November 24, 2021',
    }, {
        src: Blog4,
        alt: "Blog4",
        title: "How to Hire the Right Node.js Developer?",
        text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
        date: 'November 24, 2021',
    }

];

const LazyImage = ({src, alt, title, text, date}) => {
    const rootRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <div className="press_item">
            <div className="content_press">
                <div className="date">
                    {date}
                </div>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
            <div className="img_press">
                <img src={isVisible ? src : loading} ref={rootRef} className="img-fluid" alt={alt}/>
            </div>
        </div>
    );
};

function Recentpress(props) {
    return (
        <div className="recent_press">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="press_grid">
                            {blogAPI.map((el, i) => (
                                <LazyImage
                                    alt={el.alt}
                                    src={el.src} title={el.title} text={el.text} key={i} date={el.date}
                                    />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Recentpress;