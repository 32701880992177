import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Banner(props) {
    return (
        <div className="press_banner">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="breadcrumb_tek">
                            <ul>
                                <li>Tekrevol</li>
                                <li>
                                    <svg width="17" height="8" viewBox="0 0 17 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464465C12.9763 0.269203 12.6597 0.269203 12.4645 0.464465C12.2692 0.659727 12.2692 0.97631 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.73079 12.9763 7.73079 13.1716 7.53553L16.3536 4.35355ZM4.37114e-08 4.5L16 4.5L16 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                            fill="black"/>
                                    </svg>
                                </li>
                                <li>Insights</li>
                                <li>
                                    <svg width="17" height="8" viewBox="0 0 17 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464465C12.9763 0.269203 12.6597 0.269203 12.4645 0.464465C12.2692 0.659727 12.2692 0.97631 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.73079 12.9763 7.73079 13.1716 7.53553L16.3536 4.35355ZM4.37114e-08 4.5L16 4.5L16 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                                            fill="black"/>
                                    </svg>
                                </li>
                                <li>Press</li>
                            </ul>
                            <h1>Our publications</h1>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="blog_content">
                            <p>We share our expertise in software development services, including web, mobile, design,
                                and DevOps, with various platforms.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;