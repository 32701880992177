import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Marqeebanner(props) {
    const texts = props.props;
    return (
        <div className="marqee_banner">
            <Container className="h-100" fluid>
                <Row className="h-100">
                    <Col md={12} className="m-auto">
                        <div className="marquee">
                            <p>{texts}</p>
                            <p>{texts}</p>
                            <p>{texts}</p>
                            <p>{texts}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Marqeebanner;