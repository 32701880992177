import React from 'react';
import Banner from "./banner";
import Presscontent from "./presscontent";
import Recentpress from "./recentpress";
import Partner from "../home/partner";
import Layout from "../layout/layout";

function Index(props) {
    return (
        <Layout>
            <Banner/>
            <Presscontent/>
            <Recentpress/>
            <Partner/>
        </Layout>
    );
}

export default Index;