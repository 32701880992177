import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Loader from "../components/Loader";

const responsive = {
    tablet: {
        breakpoint: {max: 900, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1.5
    }
};

function Partner(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/technology-partners`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (
        <>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <div className="tekrevol_partner tekrevol_gray_bg p-100">
                        <Container className="h-100">
                            <Row className="h-100">
                                <Col xs={12} md={12} lg={12} xl={11} xxl={11} className="tekrevol_partner_title">
                                    <div className="example" data-text="Who With Us">
                                        <h2>Technology Partnerships</h2>
                                    </div>
                                    <p>We contribute to these partnership programs, supported by global technology
                                        experts.
                                        Partnering with industry leaders, we offer quality technology solutions that
                                        meet
                                        your
                                        business needs.</p>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_partner_logo">
                                    <div className="item_logo">
                                        {userData?.map((el, i) => (
                                            <div className="logo_box" key={i}>
                                                <img src={el.image.path} className="img-fluid" alt={el.name}/>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="item_logo_slide">
                                        <Carousel responsive={responsive} className="carousel_featured">
                                            {userData?.map((el, i) => (
                                                <div className="logo_box" key={i}>
                                                    <img src={el.path} className="img-fluid" alt={el.name}/>
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            }
        </>
    );


}

export default Partner;