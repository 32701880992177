import React, {useEffect, useState} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {useStaticQuery, graphql} from "gatsby"
import PropTypes from "prop-types"
import Marqeebanner from "../home/marqeebanner";


function Layout({children, slug}) {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 100;
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };
        document.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    let className = "main_container"
    return (
        <div className={className}>
            {scrolled ? '' : <Marqeebanner props="Site is under construction. Some pages may be incomplete. We apologies for any
                                inconvenience."/>}
            <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
            {children}
            <Footer/>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
export default Layout;

